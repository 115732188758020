import React, { useState } from 'react'
import hj from './public/hjk.jpg'
import fg from './public/option(1).jpg'
import {useNavigate} from 'react-router-dom'
import Navba from './empnav';
import Footer from './footer';

function Home(){
    const navigate= useNavigate();
    function changestudent(){
        navigate('/student')
    }
    function changeadmin(){
        navigate('/acheck')
    }
    return(
        <>
        <Navba/>
        <div className="row">
        <div className="col-12">
             <div className="intro">
              <img src={hj}/>
              <div className="overlay">
                <div className="text" ><p data-aos="fade-up" data-aos-delay="100">A Student community for a better preparation</p>
                <button onClick={changestudent} className='btn btn-light'>Student</button>
                </div>
              </div>
             </div>
        </div>
      </div>
      <div className="row pt-5">
        <div className="col-12 d-flex justify-content-center ">
          <p className="h2  text-line" style={{"font-family": "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif"}}>Our Vision</p>
        </div>
        <div className=" col-sm-12 col-md-6 mt-2 p-5 " data-aos="fade-right">
          <p className="h5" style={{"text-align":'justify','font-weight':' lighter'}}>"Empowering every college student to excel through seamless access to quality notes and study materials, fostering a community of knowledge, growth, and success."</p>
        </div>
        <div className="col-sm-12 col-md-6  mt-2 p-4 d-flex justify-content-center" data-aos="fade-left">
         <img style={{"height":'250px','width':'300px', 'justify-content': 'center','border': '2px solid #aa965b', 'border-radius':"5px" }}src={fg}/>
         </div>
      </div>
        <Footer/>
        </>
        
    );
}
export default Home;