import { Link } from "react-router-dom";
import Footer from "./footer";
import Navbar from "./navbar";
import hj from './public/ty.jpg'
import fg from './public/community.jpg'

export default function Contact(){
    return(
        <>
        <Navbar/>
        <div className="row">
        <div className="col-12">
             <div className="intro">
              <img src={hj}/>
              <div className="overlay">
              </div>
             </div>
        </div>
      </div>
      <div className="row pt-5">
        <div className="col-12 d-flex justify-content-center ">
          <p className="h2  text-line" style={{"font-family": "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif"}}>Thank you for visiting us</p>
        </div>
        <div className=" col-sm-12 col-md-6 mt-2 p-5 " data-aos="fade-right">
          <p className="h5" style={{"text-align":'justify','font-weight':' lighter'}}>"Empowering every college student to excel through seamless access to quality notes and study materials, fostering a community of knowledge, growth, and success."Thats our promise</p>
        </div>
        <div className="col-sm-12 col-md-6  mt-2 p-4 d-flex justify-content-center" data-aos="fade-left">
         <img style={{"height":'100%','width':'300px', 'justify-content': 'center','border': '2px solid #aa965b', 'border-radius':"5px" }}src={fg}/>
         </div>
      </div>
      <div className="row d-flex justify-content-center">
        <div className="col-12 d-flex justify-content-center ">
        <p className="h5" style={{"text-align":'justify','font-weight':' lighter'}}>Your feedbacks are appreciated for further development</p>
        </div>
        <div className="col-12 d-flex justify-content-center">
        <Link className="btn btn-dark" to="https://docs.google.com/forms/d/e/1FAIpQLSePnSIyO33ZRe1haafSBtymcAWzeEKvmeW18IatUSmmceDzUA/viewform">Contact us</Link>
        </div>
      </div>
        <Footer/>
        </>
    )
}