import React from 'react'
import { BrowserRouter,Routes,Route,useNavigate,Link } from 'react-router-dom';
import Navba from './empnav';
import Footer from './footer';
import cs from './public/option(5).jfif'

function Student(){
    return(
        <>
             <Navba/>
             <div className='row mt-2'>
            <div className='col-12 d-flex justify-content-center'>
                <h1>Welcome to Scribbleworks</h1>
            </div>
        </div>
        <div className=' row pb-5'>
        <section className='vh-100 mb-5 d-flex justify-content-center ' >
  <div className="container m-3 h-100">
    <div className="row d-flex align-items-center justify-content-center mb-5">
      <div className="col-md-8 col-lg-7 col-xl-6">
        <img src={cs} style={{'height':'100%'}}
          className="img-fluid" alt="Phone image"/>
      </div>
      <div className="col-md-7 col-lg-5 col-xl-5 offset-xl-1 py-5">
        <form >
         <h1>Make a Move</h1>
          <div data-mdb-input-init className="form-outline mb-4">
            <label className="form-label" for="form1Example13">Already have an account</label>
            <Link to={'/login'} className={' d-flex justify-content-center align-items-center btn btn-dark'}>login</Link>
          </div>

          <div data-mdb-input-init className="form-outline mb-4">
            <label className="form-label" for="form1Example23">New to scribbleworks</label>
            <Link to={'/register'}  className={' d-flex justify-content-center btn btn-dark'}>newuser</Link>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
    
        </div>  
        <div className='row'>
            <div className='col-12 '>
                <Footer/>
            </div>
            </div>  
</>
    );
}
export default Student;