import React from 'react'
import { Link } from 'react-router-dom';
import cs from './public/option(5).jfif'
import Navba from './empnav';
import Footer from './footer';

function Admin(){
    return(
        <>
             <Navba/>
             <div className='row mt-2'>
            <div className='col-12 d-flex justify-content-center'>
                <h1>Admin Pannel</h1>
            </div>
        </div>
        <div className=' row'>
        <section className='vh-100 d-flex justify-content-center ' >
  <div className="container m-3 h-100">
    <div className="row d-flex align-items-center justify-content-center">
      <div className="col-md-8 col-lg-7 col-xl-6">
        <img src={cs} style={{'height':'100%'}}
          className="img-fluid" alt="Phone image"/>
      </div>
      <div className="col-md-7 col-lg-5 col-xl-5 offset-xl-1 py-5">
        <form >
          <div data-mdb-input-init className="form-outline">
            <label className="form-label" for="form1Example13">Make an entry to the Database</label>
            <Link to={'/ft'} className={' d-flex justify-content-center align-items-center btn btn-dark'}>Upload notes</Link>
          </div>

        </form>
      </div>
    </div>
  </div>
</section>
    
        </div>  
        <div className='row'>
            <div className='col-12 '>
                <Footer/>
            </div>
            </div>  
</>
        
    );
}
export default Admin;