export default function Footer(){
    return(
        <>
          <footer
          class="text-center text-lg-start text-white mt-3"
          style={{"background-color":" #000"}}
          >
    <section
             class="d-flex justify-content-between p-4"
             style={{"background-color":" #aa965b"}}
             >
      <div class="me-5">
        <span>Get connected with us on social networks:</span>
      </div>
      
      <div>
        <a href="https://x.com/scribble_works?t=g-QjVIl6jRy52tIjQ634Ng&s=09" class="text-white me-4">
          <i class="fab fa-twitter"></i>
        </a>
        <a href="https://www.instagram.com/_scribblers.com_?igsh=dmU4NnR4dXRoYmoz" class="text-white me-4">
          <i class="fab fa-instagram"></i>
        </a>
      </div>
     </section>
    
    <section class="">
      <div class="container text-center text-md-start mt-5">
        <div class="row mt-3">
          <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
            <h6 class="text-uppercase fw-bold">Scribble Works</h6>
            <hr
                class="mb-4 mt-0 d-inline-block mx-auto"
                style={{'width': '60px', 'background-color': '#aa965b', 'height': "2px"}}
                />
            <p className="text-danger">
            Disclaimer:* We provide notes for reference purposes only.

* The answers given can be sometimes changes

  * use by your cause


* and we are not affiliated to any organisation
            </p>
          </div>
          
          <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
            <h6 class="text-uppercase fw-bold">Products</h6>
            <hr
                class="mb-4 mt-0 d-inline-block mx-auto"
                style={{"width":' 60px', 'background-color':'#aa965b', 'height':' 2px'}}
                />
            <p>
              <a href="#!" class="text-white" style={{"text-decoration":"none"}}>Offline notes</a>
            </p>
            <p>
              <a href="#!" class="text-white"  style={{"text-decoration":"none"}}>Online notes</a>
            </p>
            <p>
              <a href="#!" class="text-white"  style={{"text-decoration":"none"}}>Question bank</a>
            </p>
          </div>
          
          <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
            <h6 class="text-uppercase fw-bold">Contact</h6>
            <hr class="mb-4 mt-0 d-inline-block mx-auto" style={{'width': '60px', 'background-color': '#aa965b', 'height': '2px'}}/>
            <p><i class="fas fa-envelope mr-3"></i> partners1204@gmail.com</p>
          </div>
          </div>
        </div>
    </section>
    
    <div class="text-center p-3" style={{"background-color": "rgba(0, 0, 0, 0.2)"}}>© 2024 Designed & Developed by:
      <a class="text-white" style={{"text-decoration":"none"}} href="#!"
         >Partners</a>
    </div>
    </footer>  

        </>
    )
};