import React from 'react';
import { BrowserRouter,Routes,Route,useNavigate,Link } from 'react-router-dom';
import Navbar from './navbar';
import Footer from './footer';
import hj from './public/hjk.jpg';
import fg from './public/option(1).jpg';
import cse from './public/cse.jfif';
import ece from './public/ece.jfif';
import eee from './public/eee.jfif';
import it from './public/it.jfif';
import mech from './public/mech.jfif';
import csbs from './public/csbs.jfif';
import bme from './public/bme.jfif';
import aids from './public/aids.jfif';

function Posresult(){
    return(
        <>
        <Navbar/>
        <div className="row">
        <div className="col-12">
             <div className="intro">
              <img src={hj}/>
              <div className="overlay">
                <div className="text" ><p data-aos="fade-up" data-aos-delay="100">A Student community for a better preparation</p></div>
              </div>
             </div>
        </div>
      </div>
     

      <div className="bg-white">
       
        <div className="row pt-5">
        <div className="col-12 d-flex justify-content-center ">
          <p className="h2  text-line" style={{"font-family":" 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif"}}>Our Vision</p>
        </div>
        <div className=" col-sm-12 col-md-6 mt-2 p-5 " data-aos="fade-right">
          <p className="h5" style={{"text-align":"justify",'font-weight': 'lighter'}}>"Empowering every college student to excel through seamless access to quality notes and study materials, fostering a community of knowledge, growth, and success."</p>
        </div>
        <div className="col-sm-12 col-md-6  mt-2 p-4 d-flex justify-content-center" data-aos="fade-left">
         <img style={{"height":'250px','width':'300px', 'justify-content':'center', 'border':' 2px solid #aa965b', 'border-radius':'5px'}} src={fg}/>
         </div>
      </div>
      <div className="row mt-5">
        <div className="col-12 d-flex justify-content-center ">
          <p className="h2  text-line" style={{"font-family":"'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif"}}>Choose your Department</p>
        </div>
      </div>
    
      <div className="row" data-aos="zoom-in">
        <div className="col-sm-6 col-md-3 mt-2 d-flex justify-content-center">
          <div className="cont">
            <img className="imgop" src={cse}/>
            <div className="overoption">
              <div className="textopt">
                <Link className='text-white' to={'/viewbookcse'} style={{textDecoration:'none'}}>CSE</Link>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-md-3 mt-2 d-flex justify-content-center">
          <div className="cont">
            <img className="imgop" src={ece}/>
            <div className="overoption">
              <div className="textopt">
              <Link className='text-white' to={'/viewbookece'} style={{textDecoration:'none'}}>ECE</Link>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-md-3 mt-2 d-flex justify-content-center">
          <div className="cont">
            <img className="imgop" src={eee}/>
            <div className="overoption">
              <div className="textopt">
              <Link className='text-white' to={'/viewbookeee'} style={{textDecoration:'none'}}>EEE</Link>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-md-3 mt-2 d-flex justify-content-center">
          <div className="cont">
            <img className="imgop" src={it}/>
            <div className="overoption">
              <div className="textopt">
              <Link className='text-white' to={'/viewbookit'} style={{textDecoration:'none'}}>IT</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row" data-aos="zoom-in">
        <div className="col-sm-6 col-md-3 mt-2 d-flex justify-content-center">
          <div className="cont">
            <img className="imgop" src={bme}/>
            <div className="overoption">
              <div className="textopt">
              <Link className='text-white' to={'/viewbookbme'} style={{textDecoration:'none'}}>BME</Link>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-md-3 mt-2 d-flex justify-content-center">
          <div className="cont">
            <img className="imgop" src={csbs}/>
            <div className="overoption">
              <div className="textopt">
              <Link className='text-white' to={'/viewbookcsbs'} style={{textDecoration:'none'}}>CSBS</Link>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-md-3 mt-2 d-flex justify-content-center">
          <div className="cont">
            <img className="imgop" src={aids}/>
            <div className="overoption">
              <div className="textopt">
              <Link className='text-white' to={'/viewbookaids'} style={{textDecoration:'none'}}>AI&DS</Link>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-md-3 mt-2 d-flex justify-content-center">
          <div className="cont">
            <img className="imgop" src={mech}/>
            <div className="overoption">
              <div className="textopt">
              <Link className='text-white' to={'/viewbookmech'} style={{textDecoration:'none'}}>MECH</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
       
        <Footer/>
        </>
        
    );
}
/* <h1>hello student</h1>
        <h2>success ra nanba</h2>
        <Link to={'/search'}>search book</Link><br/>
        <Link to={'/viewbook'}>view books</Link>*/
export default Posresult;