import { useNavigate } from "react-router-dom";
import {auth} from '../config/firebase/fbconfig';
import { signOut } from "firebase/auth";
import cs from './public/login.jfif'
import Navbar from "./navbar";
import Footer from "./footer";

export default function Signout(){
    const navi = useNavigate()
    function signout(){
     signOut(auth);
        navi('/')
    } 
    return(
        <>
        <Navbar/>
        <div className='row mt-2'>
            <div className='col-12 d-flex justify-content-center'>
                <h1>Scribbleworks</h1>
            </div>
        </div>
        <div className=' row pb-5'>
        <section className='vh-100 mb-5 d-flex justify-content-center ' >
  <div className="container m-3 h-100">
    <div className="row d-flex align-items-center justify-content-center mb-5">
      <div className="col-md-8 col-lg-7 col-xl-6">
        <img src={cs} style={{'height':'100%'}}
          className="img-fluid" alt="Phone image"/>
      </div>
      <div className="col-md-7 col-lg-5 col-xl-5 offset-xl-1 py-5">
        <form onSubmit={signout}  >
         <h1>Do you wish to Sign out</h1>

          <input type="submit" data-mdb-button-init data-mdb-ripple-init className=" btn btn-dark btn-lg btn-block d-flex justify-content-center" value='Yes'></input>

        </form>
      </div>
    </div>
  </div>
</section>
    
        </div>  
        <div className='row mt-5'>
            <div className='col-12 mt-5'>
                <Footer/>
            </div>
            </div>  
        </>
    )

}