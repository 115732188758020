import Footer from "./footer";
import Navbar from "./navbar";
import hj from './public/about3.jpg'
import hj3 from './public/offbook.png'
import hj1 from './public/about1.jpeg'
import hj2 from './public/about2.jpeg'
import fg from './public/option(1).jpg'
import js1 from './public/prod1.png'
import js2 from './public/prod2.png'
import js3 from './public/prod3.png'

 
export default function About(){
    return(
        <div style={{"overflow-x":"hidden"}}>
        <Navbar/>
        <div className="row">
        <div className="col-12 justify-content-center">
             <div className="intro">
              <img src={hj} style={{"overflow-x":"hidden"}}/>
              <div className="overlay">
                <div className="textabout"><p data-aos="fade-up" data-aos-delay="100">It all begins from a spark</p>
                </div>
              </div>
             </div>
        </div>
      </div>
        <div className="row pt-5" >
        <div className="col-12 d-flex justify-content-center ">
          <p className="h2  text-line" style={{"font-family": "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif"}}>Welcome to Scribble Works</p>
        </div>
        <div className=" col-sm-12 col-md-6 mt-2 p-5 " data-aos="fade-right">
          <p className="h5" style={{"text-align":'justify','font-weight':' lighter'}}>Scribble Works was born out of a simple yet powerful idea: to create a space where college students can easily access high-quality notes and study materials. As former students ourselves, we understand the challenges of juggling multiple courses, managing time, and finding reliable resources. We remember the late-night study sessions, the endless search for the perfect textbook, and the struggle to compile comprehensive notes. </p>
        </div>
        <div className="col-sm-12 col-md-6  mt-2 p-4 d-flex justify-content-center" data-aos="zoom-in">
         <img style={{"height":'250px','width':'300px', 'justify-content': 'center','border': '2px solid #aa965b', 'border-radius':"5px" }}src={hj1}/>
         </div>
         <div className="col-sm-12 col-md-6  mt-2 p-4 d-flex justify-content-center" data-aos="zoom-in">
         <img style={{"height":'250px','width':'300px', 'justify-content': 'center','border': '2px solid #aa965b', 'border-radius':"5px" }}src={hj2}/>
         </div>
         <div className=" col-sm-12 col-md-6 mt-2 p-5 " data-aos="fade-right">
          <p className="h5" style={{"text-align":'justify','font-weight':' lighter'}}>That's why we created Scribble Works—a platform designed to alleviate these stresses. Our journey began with a vision to unite students by providing them with the tools they need to excel academically. We believe that every student deserves access to the best study materials, regardless of their background or financial situation. </p>
        </div>
        <div className=" col-sm-12 mt-2 px-5 " data-aos="fade-right">
          <p className="h5" style={{"text-align":'justify','font-weight':' lighter'}}>At Scribble Works, we curate a vast collection of notes, textbooks, and study guides, all crafted by top students and educators. Our goal is to make your academic journey smoother and more enjoyable. Whether you're cramming for an exam, preparing for a presentation, or just looking to deepen your understanding of a subject, Scribble Works is here to support you.
Join our community of learners, and let's achieve academic excellence together. Welcome to Scribble Works, where your success is our story.</p>
        </div>
        </div>
        <div className="row pt-5 d-flex justify-content-center">
        <div className="col-12 d-flex justify-content-center ">
          <p className="h2  text-line" style={{"font-family": "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif"}}>What we provide</p>
        </div>
        <div className="col-sm-12 col-md-3  mt-2 px-2 d-flex justify-content-center" data-aos="zoom-in">
         <img style={{"height":'200px','width':'200px', 'justify-content': 'center','border': '2px solid #aa965b', 'border-radius':"5px" }}src={js1}/>
         </div>
         <div className="col-sm-12 col-md-3  mt-2 px-2 d-flex justify-content-center" data-aos="zoom-in">
         <img style={{"height":'200px','width':'200px', 'justify-content': 'center','border': '2px solid #aa965b', 'border-radius':"5px" }}src={js2}/>
         </div>
         <div className="col-sm-12 col-md-3  mt-2 px-2 d-flex justify-content-center" data-aos="zoom-in">
         <img style={{"height":'200px','width':'200px', 'justify-content': 'center','border': '2px solid #aa965b', 'border-radius':"5px" }}src={js3}/>
         </div>
      </div>
        <Footer/>
        </div>
    )
}