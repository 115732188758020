import { createSlice } from "@reduxjs/toolkit";


const ld = createSlice({
    name:"link",
    initialState:{
        value:{
            link:[]
        }
    },
    reducers:{
        ls:(state,action)=>{
            state.value=action.payload
        }
    }
})


export const { ls } = ld.actions
export default ld.reducer
