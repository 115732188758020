import logo from './logo.svg';
import './App.css';
import Home from './components/home';
import { BrowserRouter,Routes,Route,useNavigate } from 'react-router-dom';
import Student from './components/student';
import Admin from './components/admin';
import Login from './components/login';
import Posresult from './components/loginresult';
import Posresult2 from './components/loginresult2';
import Search from './components/search';
import Sresult from './components/sresult';
import Reg from './components/newreg';
import Newresult2 from './components/nusererr';
import Fileform from './components/notesupload';
import Admincheck from './components/adminlog';
import Result from './components/uploadr';
import Sbrdetail from './components/booksdetails';
import Cpepass from './components/cpepass';
import Cpefail from './components/cpefail';
import Cpe from './components/customerpe';
import Vbook from './components/viewbook';
import Viewer from './components/viewer';
import Error from './components/error';
import Vbookcse from './components/vbcse';
import Vbookece from './components/vbece';
import Vbookeee from './components/vbeee';
import Vbookit from './components/vbit';
import Vbookmech from './components/vbmech';
import Vbookbme from './components/vbbme';
import Vbookaids from './components/vbaids';
import Vbookcsbs from './components/vbcsbs';
import About from './components/about';
import Contact from './components/contact';
import Signout from './components/signout';
import Newpassresult2 from './components/passworderr';
import Resultf from './components/uploadf';

function App() {
  return (
    <>
      <BrowserRouter>
    <Routes>
      <Route path='/' element={<Home/>}/>
      <Route path='/about' element={<About/>}/>
      <Route path='/contact' element={<Contact/>}/>
      <Route path='/signout' element={<Signout/>}/>
      <Route path='/links' element={<Home/>}/>
      <Route path='/error' element={<Error/>}/>
      <Route path='/passerror' element={<Newpassresult2/>}/>
      <Route path='/ft' element={<Fileform/>}/>
      <Route path='/student' element={<Student/>}/>
      <Route path='/login' element={<Login/>}/>
      <Route path='/register' element={<Reg/>}/>
      <Route path='/acheck' element={<Admincheck/>}/>
      <Route path='/admin' element={<Admin/>}/>
      <Route path='/result' element={<Posresult/>}/>
      <Route path='/nusertryagain' element={<Newresult2/>}/>
      <Route path='/tryagain' element={<Posresult2/>}/>
      <Route path='/search' element={<Search/>}/>
      <Route path='/sresult' element={<Sresult/>}/>
      <Route path='/uploadstatus' element={<Result/>}/>
      <Route path='/uploadstatusf' element={<Resultf/>}/>
      <Route path='/detailsbook' element={<Sbrdetail/>}/>
      <Route path='/cpentry' element={<Cpe/>}/>
      <Route path='/cperesult' element={<Cpepass/>}/>
      <Route path='/cpefail' element={<Cpefail/>}/>
      <Route path='/viewbook' element={<Vbook/>}/>
      <Route path='/viewbookcse' element={<Vbookcse/>}/>
      <Route path='/viewbookece' element={<Vbookece/>}/>
      <Route path='/viewbookeee' element={<Vbookeee/>}/>
      <Route path='/viewbookit' element={<Vbookit/>}/>
      <Route path='/viewbookmech' element={<Vbookmech/>}/>
      <Route path='/viewbookbme' element={<Vbookbme/>}/>
      <Route path='/viewbookaids' element={<Vbookaids/>}/>
      <Route path='/viewbookcsbs' element={<Vbookcsbs/>}/>
      <Route path='/viewer' element={<Viewer/>}/>
    </Routes>
    </BrowserRouter>
    </>
    
  );
}

export default App;
