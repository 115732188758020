import { useEffect, useState } from "react";
import {db} from '../config/firebase/fbconfig'
import {getDocs,doc,addDoc,deleteDoc,collection,updateDoc} from 'firebase/firestore'
import { useSelector,useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {ls} from "../features/link"
import {ps} from "../features/pages"
import Navbar from "./navbar";
import Footer from "./footer";
export default function Vbookaids(){
    const navi = useNavigate()
    const dispatch = useDispatch();
    const ref=collection(db,'aids')
    const [arr,setArr]=useState([])
    const data = useSelector((state)=>state.userd.value)
    const da ={
        phone:data
    };
    console.log(JSON.stringify(data))
    console.log(da)
    
    
    useEffect( ()=>{
        const data = async()=>{
            const book = await getDocs(ref)
            const filtered = await book.docs.map((data)=>({...data.data()}))
            await setArr(filtered)
        }
        data().catch((err)=>{
            console.log(err)
            navi('/')
        }
            
    )
    },[] )

   
   
   
    console.log(typeof(arr))
   console.log(arr[0])

   
    
    return(
        <>
        <Navbar/>
        <div className="row mt-2">
            <div className="col-12 d-flex justify-content-center h1">
                Artificial Intelligence and Machine learning
            </div>
        </div>
        <div>
        <table className="table mt-3">
            <thead className="thead-dark">
            <tr>
                <th>Subject code</th>
                <th>Subject name</th>
                <th>Subject Sem</th>
                <th>Viewer</th>
            </tr>
            </thead>
            <tr>
            </tr>
            { arr.map((data)=>{const url=data.slink+'.pdf#toolbar=0&navpanes=0&scrollbar=0';
               const viewer = () =>{
                dispatch(ps(data.pages))
                dispatch(ls(url))
                navi('/viewer')} 
            return <tr>
                <td>{data.scode}</td>
                <td>{data.sname}</td>
                <td>{data.sem}</td>
                <td><button className="btn text-warning btn-dark" onClick={viewer}>view</button></td>
            </tr>})}
        </table>
        </div>
        <Footer/>
        </>
    )
}