import React, { useEffect, useState } from 'react'
import { auth , google } from '../config/firebase/fbconfig'
import{createUserWithEmailAndPassword , signInWithPopup}from 'firebase/auth'
import { BrowserRouter,Routes,Route,useNavigate,Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { profile } from '../features/logred';
import cs from './public/reg.jfif'
import Navba from './empnav';
import Footer from './footer';

export default function Reg(){
    const dispatch=useDispatch()
    
    const navi = useNavigate();
    const [pass,setPass]=useState('');
    const [email,setEmail]=useState('');
     
     async function sumit(e){
        e.preventDefault();  
        if(pass.length>=6){
          try{
            await createUserWithEmailAndPassword(auth,email,pass)
            navi('/login')
          }
          catch(err){
            
              navi('/nusertryagain')
          }
        }
        else{
          navi('/passerror')
        }
        
           
    console.log("clicked")
        
    }
    
    return(
         <>
        <Navba/>
        <div className='row mt-2'>
            <div className='col-12 d-flex justify-content-center'>
                <h1>Welcome to Scribbleworks</h1>
            </div>
        </div>
        <div className=' row pb-5'>
        <section className='vh-100 mb-5 d-flex justify-content-center ' >
  <div className="container m-3 h-100">
    <div className="row d-flex align-items-center justify-content-center mb-5">
      <div className="col-md-8 col-lg-7 col-xl-6">
        <img src={cs} style={{'height':'100%'}}
          className="img-fluid" alt="Phone image"/>
      </div>
      <div className="col-md-7 col-lg-5 col-xl-5 offset-xl-1 py-5">
        <form onSubmit={sumit}  >
         <h1>Registration</h1>
         <div data-mdb-input-init className="form-outline mb-4">
            <input type="text" id="form1Example13" className="form-control form-control-lg"placeholder='enter email' onChange={(e)=>setEmail(e.target.value)} name='username' />
            <label className="form-label" for="form1Example13">Email address</label>
          </div>

          <div data-mdb-input-init className="form-outline mb-4">
            <input type="password" id="form1Example23" className="form-control form-control-lg " placeholder='enter password' onChange={(e)=> setPass(e.target.value)} name='pass' />
            <label className="form-label" for="form1Example23">Password (must contain 8 characters)</label>
          </div>

          <input type="submit" data-mdb-button-init data-mdb-ripple-init className=" btn btn-dark btn-lg btn-block" value='Sign-up'></input>
        </form>
      </div>
    </div>
  </div>
</section>
    
        </div>  
        <div className='row mt-5'>
            <div className='col-12 mt-5'>
                <Footer/>
            </div>
            </div>  
        </>
    )
}