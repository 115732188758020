import { configureStore } from "@reduxjs/toolkit";
import sessionStorage from "redux-persist/es/storage/session";
import userReducer from "../features/logred"
import bookReducer from "../features/bookd";
import linkReducer from "../features/link";
import pageReducer from "../features/pages"
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { combineReducers } from '@reduxjs/toolkit';


 
export const store = configureStore({
    reducer:{ 
        userd:userReducer,
        bookd:bookReducer,
        linkd:linkReducer,
        paged:pageReducer
    }
       
       
    
})

