import { createSlice } from "@reduxjs/toolkit";


const pd = createSlice({
    name:"page",
    initialState:{
        value:{
            pagen:""
        }
    },
    reducers:{
        ps:(state,action)=>{
            state.value=action.payload
        }
    }
})


export const { ps } = pd.actions
export default pd.reducer