import React, { useEffect, useState } from 'react'
import {auth , google} from '../config/firebase/fbconfig'
import {signInWithEmailAndPassword , signInWithPopup} from'firebase/auth'
import { BrowserRouter,Routes,Route,useNavigate,Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import cs from './public/login.jfif'
import { profile } from '../features/logred';
import Navba from './empnav';
import Footer from './footer';

export default function Login(){
    const dispatch = useDispatch();
    const navi = useNavigate();
    const [email,setEmail]=useState('');
    const [pass,setPass]=useState('');
    const valuetest = useSelector((state)=>state.userd.value);
     
    async function sumit(e){
        e.preventDefault();
        try{
          await signInWithEmailAndPassword(auth,email,pass)
          navi('/result')
        }
        catch(err){
          
            navi('/tryagain')
            console.log("clicked")
    console.log(valuetest);
      }
}
    
    return(
        <>
        <Navba/>
        <div className='row mt-2'>
            <div className='col-12 d-flex justify-content-center'>
                <h1>Welcome to Scribbleworks</h1>
            </div>
        </div>
        <div className=' row pb-5'>
        <section className='vh-100 mb-5 d-flex justify-content-center ' >
  <div className="container m-3 h-100">
    <div className="row d-flex align-items-center justify-content-center mb-5">
      <div className="col-md-8 col-lg-7 col-xl-6">
        <img src={cs} style={{'height':'100%'}}
          className="img-fluid" alt="Phone image"/>
      </div>
      <div className="col-md-7 col-lg-5 col-xl-5 offset-xl-1 py-5">
        <form onSubmit={sumit}  >
         <h1>Login</h1>
          <div data-mdb-input-init className="form-outline mb-4">
            <input type="text" id="form1Example13" className="form-control form-control-lg" placeholder='enter email' onChange={(e)=>setEmail(e.target.value)} name='phone' />
            <label className="form-label" for="form1Example13">Email address</label>
          </div>

          <div data-mdb-input-init className="form-outline mb-4">
            <input type="password" id="form1Example23" className="form-control form-control-lg" placeholder='enter password' onChange={(e)=> setPass(e.target.value)} name='pass' />
            <label className="form-label" for="form1Example23">Password</label>
          </div>

          <input type="submit" data-mdb-button-init data-mdb-ripple-init className=" btn btn-dark btn-lg btn-block" value='Sign-in'></input>
        </form>
      </div>
    </div>
  </div>
</section>
    
        </div>  
        <div className='row mt-5'>
            <div className='col-12 mt-5'>
                <Footer/>
            </div>
            </div>  
        </>
    )
}