import { useEffect, useState } from "react";
import { useSelector,useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {ls} from "../features/link"
export default function Vbook(){
    const navi = useNavigate()
    const dispatch = useDispatch();
    const [arr,setArr]=useState([])
    const data = useSelector((state)=>state.userd.value)
    const da ={
        phone:data
    };
    console.log(JSON.stringify(data))
    console.log(da)
    
    
    useEffect( ()=> {const bookdb= async () => {
        const re = await fetch('https://project-scribblework.onrender.com/cbook',{
            method:"POST",
            headers:{
                'Content-Type':"application/json;charset=UTF-8"
            },
            body:JSON.stringify(da)
        })
            const dat =await re.json()
            console.log(dat);
            await setArr(dat)
    } 
    bookdb().catch((err)=>{
        navi('/error');
    })},[] )

   
   
   
    console.log(typeof(arr))
   console.log(arr[0])

   
    
    return(
        <>
        <ul>
            { arr.map((data)=>{const url=data.slink+'.pdf#toolbar=0&navpanes=0&scrollbar=0';
               const viewer = () =>{
                dispatch(ls(url))
                navi('/viewer')} 
            return <ul>
                <li key={data.id}><h1>{data.scode}</h1><br/><button onClick={viewer}>view</button></li>
            </ul>})}
        </ul>
        </>
    )
}