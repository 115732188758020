import { useState } from "react"
import { db} from "../config/firebase/fbconfig"
import {addDoc,collection} from 'firebase/firestore'
import { useNavigate } from "react-router-dom";

export default function Fileform(){
    const navi = useNavigate();
    const [scode,setScode]=useState('');
    const [sname,setSname]=useState('');
    const [sem,setSem]=useState('');
    const [dept,setDept]=useState('');
    const [pages,setPages]=useState('1');
    const[mp,setMp]=useState(false)
    const [sulink1,setSulink1]=useState('')
    const [sulink2,setSulink2]=useState('')
    const [slink,setSlink]=useState([])
    async function filehandle(e){
        e.preventDefault();
    const data = {
        scode:scode,
        sname:sname,
        sem:sem,
        pages:pages,
        slink:slink
    }
    const ref = collection(db,dept)
    if(pages==1){
        try{
            await addDoc(ref,{
                scode:scode,
                sname:sname,
                sem:sem,
                pages:pages,
                slink:slink
            })
            navi('/uploadstatus')
        }catch(err){
            navi("/uploadstatusf")
            console.log(dept)
        }
    }
    else{
        const fulllink = [sulink1,sulink2]
        try{
            await addDoc(ref,{
                scode:scode,
                sname:sname,
                sem:sem,
                pages:pages,
                slink:fulllink
            })
            navi('/uploadstatus')
        }catch(err){
            navi("/uploadstatusf")
            console.log(dept)
        }
    }
        
    }
    return(
        <>
        <form onSubmit={filehandle}>
        <input type="text" onChange={(e)=>setScode(e.target.value)} placeholder="sub code"></input><br/>
        <input type="text" onChange={(e)=>setSname(e.target.value)} placeholder="sub name"></input><br/>
        <input type="text" onChange={(e)=>setSem(e.target.value)} placeholder="sub sem"></input><br/>
        <input type="text" onChange={(e)=>setDept(e.target.value)} placeholder="dept name"></input><br/>
        <input type="checkbox" value={true} onChange={(e)=>setMp(e.target.value)}></input><span>does book contain Multiple pages(if no don't click it)</span><br/>
        {mp?<><input type="text" onChange={(e)=>setPages(e.target.value)} placeholder="no of Pages"></input><br/>
        <input type="text" onChange={(e)=>setSulink1(e.target.value)} placeholder="1st part of link"></input><br/>
        <input type="text" onChange={(e)=>setSulink2(e.target.value)} placeholder="2nd part of the link"></input><br/></>:<><input type="text" onChange={(e)=>setSlink(e.target.value)} placeholder="paste the link"></input><br/></>}
        
        <input type="submit" value='submit'></input>
        </form>
        </>
    )
}