import React from 'react'
import {useNavigate} from 'react-router-dom'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

function Sresult(){
    const bdata = useSelector((state)=>state.bookd.value);
    const navigate= useNavigate();
    function changestudent(){
        navigate('/result')
    }
    
    return(
        <>
        <h1>{bdata.scode}</h1>
        <h2>{bdata.sname}</h2>
        <Link to={'/detailsbook'}>details and buy link</Link><br/>
        <button onClick={changestudent}>Home</button>
        </>
        
    );
}
export default Sresult;