import { Link } from "react-router-dom"
import logo from "./public/logo.png"

export default function Navbar(){
    const style1 = {
        "color":"#aa965b",
        "font-family":"'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif"
        
    };
    return(
        <>
            <div className="col-12">
      <nav className="navbar navbar-expand-lg navbar-light " style={{'background-color': '#000'}}>
      <Link to={'/'} className="navbar-brand d-flex align-items-center" style={{"color": "#aa965b","font-family": "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif"}}>
      Scribble Works</Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navdata" style={{"color": "#aa965b"}}>
          <span  style={{"color": "#aa965b"}} ><i className="fas fa-bars"></i></span>
        </button>
        <div className="collapse navbar-collapse justify-content-end " id="navdata">
          <ul className="navbar-nav " >
            <li className="nav-item ">
              <Link to={'/result'} className="nav-link"  style={style1}>Home</Link>
            </li>
            <li className="nav-item">
              <Link to={'/about'} className="nav-link"  style={style1}>About</Link>
            </li>
            <li className="nav-item">
              <Link to={'/contact'} className="nav-link"  style={style1}>Contact</Link>
            </li>
            <li className="nav-item">
              <Link to={'/signout'} className="nav-link"  style={style1}>Sign out</Link>
            </li>
          </ul>
        </div>
       
      </nav>
      </div>

        </>
    )
}