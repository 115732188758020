import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Admincheck(){
    const navi = useNavigate();
    const[auser,setAuser]=useState('');
    const[apass,setApass]=useState('');
    function handler(e){
        e.preventDefault();
        const data = {
            auser:auser,
            apass:apass
        }
        fetch('https://project-scribblework.onrender.com/acheck',{
            method:"POST",
            headers:{
                'Content-Type':"application/json;charset=UTF-8"
            },
            body:JSON.stringify(data)
        }).then((re)=>
            re.json()
        ).then((data)=>{
            console.log(data);
            if(data.status=="success"){
                navi('/admin')
        }
            
        })
    }
    return(
        <>
        <form onSubmit={handler}>
            <input type="text" placeholder="username" onChange={(e)=>setAuser(e.target.value)}></input>
            <input type="password" placeholder="password" onChange={(e)=>setApass(e.target.value)}></input>
            <input type="submit" value="submit" ></input>
        </form>
        </>
    )

}