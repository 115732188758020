import React from 'react'
import { BrowserRouter,Routes,Route,useNavigate,Link } from 'react-router-dom';

function Newresult2(){
    return(
        <>
        <h1>hello student</h1>
        <h2>user not registered , follow criteria to create account</h2>
        <p>creating account with existing email</p>
        <p>creating password without criteria</p>
        <Link to={'/register'}>try again</Link>
        </>
        
    );
}
export default Newresult2;