import React from 'react'
import { BrowserRouter,Routes,Route,useNavigate,Link } from 'react-router-dom';

function Posresult2(){
    return(
        <>
        <h1>hello student</h1>
        <h2>try again </h2>
        <Link to={'/login'}>login</Link>
        </>
        
    );
}
export default Posresult2;