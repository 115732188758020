import React from 'react'
import { BrowserRouter,Routes,Route,useNavigate,Link } from 'react-router-dom';

function Newpassresult2(){
    return(
        <>
        <h1>hello student</h1>
        <h2>password must be more than 8 character</h2>
    
        <Link to={'/register'}>try again</Link>
        </>
        
    );
}
export default Newpassresult2;