import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Navbar from "./navbar";
import Footer from "./footer";
import { useNavigate } from "react-router-dom";
export default function Viewer(){
    const url =[]
    const navi = useNavigate();
    const ur = useSelector((state)=>state.linkd.value);
    const temp = ur.split(',')
    const pd = parseInt(useSelector((state)=>state.paged.value));
    console.log(pd)
    console.log(temp[0],temp[1])
    if(pd>1){
        for(var i=1; i <= pd ;i++){
            if(i<10){
                const utemp = temp[0]+"00"+i+temp[1]
                url.push(utemp)
            }
            else if(i>=10 && i<100){
                const utemp =temp[0]+"0"+i+temp[1]
                url.push(utemp)
            }
            else if(i>100){
                const utemp = temp[0]+i+temp[1]
                url.push(utemp)
            }
    
        }
    }
    else{
        url.push(ur)
    }
    
    console.log(url)
    return(
        <>
        <Navbar/>
        <h1 className="d-flex justify-content-center">Happy Learning</h1>
        <div className="row mt-2">
            
                {url.map((data)=>
                <>
                    <div className="col-12 d-flex justify-content-center">
                    <img src={data} width="75%" height="100%" className="d-flex justify content center" style={{'object-fit':'contain'}} />
                    <br/>
                    </div>
                    
                </>
                    
                )}
            </div>
        <div className="row mt-2">
            <div className="col-12 d-flex justify-content-center">
            <button onClick={()=>navi('/result')} className="btn text-warning btn-dark">Back to Menu</button>
            </div>
        </div>
        <Footer/>
        </>
    )
}