import { createSlice } from "@reduxjs/toolkit";

const book = createSlice({
    name:"book",
    initialState:{
        value:{
            scode:"",
            sname:""
        }
    },
    reducers:{
        bprofile:(state,action)=>{
            state.value=action.payload
        }
    }
})
export const{bprofile} = book.actions;
export default book.reducer;