import { useState } from "react"
import { useNavigate } from "react-router-dom"

export default function Cpe(){
    const navi = useNavigate();
    const [phone,setPhone]=useState('')
    const [scode,setScode]=useState('')
    const data = {
        phone:phone,
        scode:scode
    }
    function cpe(e){
        e.preventDefault()
        fetch('https://project-scribblework.onrender.com/cpe',{
            method:'POST',
            headers:{
                'Content-Type':'application/json;charset=UTF-8'
            },
            body:JSON.stringify(data)
        }).then((re)=>re.json()).then((dat)=>{if(dat.status=='success'){
            navi('/cperesult')
        }else{
            navi('/cpefail')
        }})
    }
    return(
        <>
        <form onSubmit={cpe}>
            <input type="text" onChange={(e)=>setPhone(e.target.value)} placeholder="enter phonenumber"></input>
            <input type="text" onChange={(e)=>setScode(e.target.value)} placeholder="enter subjectcode"></input>
            <input type="submit" value="submit"></input>
        </form>
        </>
    )
}