import React, { useState } from 'react'
import { useSelector,useDispatch } from 'react-redux';
import { bprofile } from '../features/bookd';
import { BrowserRouter,Routes,Route,useNavigate,Link } from 'react-router-dom';

function Search(){
    const select = useSelector
    const dispatch = useDispatch();
    const navi=useNavigate();
    const[code,setCode]=useState('');
    

    function searching(e){
        e.preventDefault();
        let scode={
            code:code
        };
        fetch("http://localhost:5000/searching",{
            method:'POST',
            headers:{
                'Content-Type':'application/json;charset=UTF-8'
            },
            body:JSON.stringify(scode)
        }).then(re=>re.json()
    ).then(
        data=>{console.log(data);
            const {scode,sname} = data.body;
            console.log(scode,sname)
            dispatch(bprofile({scode,sname}))
            navi('/sresult')
        }
        
)
    }

    return(
        <>
        <form onSubmit={searching}>
            <label>search with subject code</label>
            <input type='text' onChange={(e)=>setCode(e.target.value)}></input>
            <input type='submit' value={'submit'}></input>
        </form>
        </>
        
    );
}
export default Search;